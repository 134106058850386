import {createContext} from 'react';

export const LanguageContext = createContext<{
    texts: string,
    language: string,
    switchLanguage: (lang: string) => void,
    getText: (key: string) => string,
      }>({
        texts: '',
        language: 'en',
        switchLanguage: () => {
        },
        getText: () => {
          return '';
        }
      });