import React, {CSSProperties} from 'react';

import {LoaderContainer} from './styles';

export interface ILoader {
    size?: number;
    borderWidth?: number;
    color?: number;
    style?: CSSProperties;
}

const Loader = ({size, borderWidth, color, style}: ILoader) => {
  //@ts-ignore
  return <LoaderContainer {...{size, borderWidth, color}} style={style}/>;
};

export default Loader;
