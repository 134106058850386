import React, {CSSProperties} from 'react';

import {Wrapper} from './style';

interface IGradientButton {
    text: string,
    width: number,
    height: number,
    afterBgGradient?: string,
    style: CSSProperties,
    className?: string,
}

const GradientButton = ({text, width, height, afterBgGradient, style, className}: IGradientButton) => {
  return (
    <Wrapper width={width} height={height} afterBgGradient={afterBgGradient} style={style} className={className}>
      {text}
    </Wrapper>
  );
};

export default GradientButton;