import {getAssetsPrefix} from '../../utils/getAssetsPrefix';

const ASSETS = {
  components: {
    header: {
      desktop: {
        BossFightersLogo: getAssetsPrefix('components/header/BossFightersLogo.webp'),
        HeaderBackground: getAssetsPrefix('components/header/HeaderBackground.webp'),
      },
      mobile: {
        HeaderBackground: getAssetsPrefix('components/header/mobile/HeaderBackground.webp'),
      },
    },
    footer: {
      desktop: {
        FooterBackground: getAssetsPrefix('components/footer/FooterBackground.webp'),
      },
      mobile: {
        FooterBackground: getAssetsPrefix('components/footer/mobile/FooterBackground.webp'),
      },
    },
    socialMedia: {
      desktop: {
        DiscordIcon: getAssetsPrefix('components/socialMedia/DiscordIcon.webp'),
        MediumIcon: getAssetsPrefix('components/socialMedia/MediumIcon.webp'),
        TelegramIcon: getAssetsPrefix('components/socialMedia/TelegramIcon.webp'),
        TiktokIcon: getAssetsPrefix('components/socialMedia/TiktokIcon.webp'),
        TwitterIcon: getAssetsPrefix('components/socialMedia/TwitterIcon.webp'),
        YoutubeIcon: getAssetsPrefix('components/socialMedia/YoutubeIcon.webp'),
      },
    },
    cardsSlider: {
      desktop: {
        Slide1: getAssetsPrefix('components/cardsSlider/Slide1.webp'),
        Slide2: getAssetsPrefix('components/cardsSlider/Slide2.webp'),
        Slide3: getAssetsPrefix('components/cardsSlider/Slide3.webp'),
        Slide4: getAssetsPrefix('components/cardsSlider/Slide4.webp'),
        SliderArrow: getAssetsPrefix('components/cardsSlider/SliderArrow.webp'),
      },
    },
  },
  main: {
    teaserBlock: {
      desktop: {
        BossFighters: getAssetsPrefix('main/teaserBlock/BossFighters.webp'),
        Crown: getAssetsPrefix('main/teaserBlock/Crown.webp'),
        MetaQuestIcon: getAssetsPrefix('main/teaserBlock/MetaQuestIcon.webp'),
        MetaQuest: getAssetsPrefix('main/teaserBlock/MetaQuest.webp'),
        EpicStore: getAssetsPrefix('main/teaserBlock/EpicStore.webp'),
        PlayDemoBtn: getAssetsPrefix('main/teaserBlock/PlayDemoBtn.webp'),
        SteamIcon: getAssetsPrefix('main/teaserBlock/SteamIcon.webp'),
        TeaserBlockBackground: getAssetsPrefix('main/teaserBlock/TeaserBlockBackground.webp'),
      },
      mobile: {
        TeaserBlockBackground: getAssetsPrefix('main/teaserBlock/mobile/TeaserBlockBackground.webp'),
      },
    },
    aboutBlock: {
      desktop: {
        MainBackground: getAssetsPrefix('main/aboutBlock/MainBackground.webp'),
        YoutubeButton: getAssetsPrefix('main/aboutBlock/YoutubeButton.webp'),
      },
      mobile: {
        MainBackground: getAssetsPrefix('main/aboutBlock/mobile/MainBackground.webp'),
      },
    },
    cardsBlock: {
      desktop: {
        ActiveBorderCard: getAssetsPrefix('main/cardsBlock/ActiveBorderCard.webp'),
        Arrow: getAssetsPrefix('main/cardsBlock/Arrow.webp'),
        ByOpenLoot: getAssetsPrefix('main/cardsBlock/ByOpenLoot.webp'),
        CardBackground: getAssetsPrefix('main/cardsBlock/CardBackground.webp'),
        EmailIcon: getAssetsPrefix('main/cardsBlock/EmailIcon.webp'),
        MainBackground: getAssetsPrefix('main/cardsBlock/MainBackground.webp'),
        SendIcon: getAssetsPrefix('main/cardsBlock/SendIcon.webp'),
      },
      mobile: {
        MainBackground: getAssetsPrefix('main/cardsBlock/mobile/MainBackground.webp'),
      },
    },
    mobileContactBlock: {
      ByOpenLoot: getAssetsPrefix('main/mobileContactBlock/ByOpenLoot.webp'),
      MainBackground: getAssetsPrefix('main/mobileContactBlock/MainBackground.webp'),
    }
  },
};

export default ASSETS