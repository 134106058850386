import styled, {css} from 'styled-components';

export const Wrapper = styled.button<{ width: number, height: number, afterBgGradient?: string }>`
  position: relative;
  top: 0;
  left: 0;

  width: ${({width}) => width}vw;
  height: ${({height}) => height}vw;

  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;

  text-transform: uppercase;

  ${({afterBgGradient}) => afterBgGradient && css`
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(${afterBgGradient});
      filter: blur(15px);
      z-index: -1;
    }
  `};
`;