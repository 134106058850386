import {AxiosResponse} from 'axios';

import makeRequest from './request';

export async function subscribeToNewsLetter(email: string) {
  return makeRequest('POST', '/api/users/news-letters-subscribe', {email});
}

export async function getHotLink(): Promise<AxiosResponse<{ link: string }>> {
  return makeRequest('GET', '/api/hot-link');
}