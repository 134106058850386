import React from 'react';

import AppContent from '../AppContent';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import {Wrapper} from './styles';

const DefaultLayout = () => {
  return (
    <Wrapper>
      <Header/>
      <AppContent/>
      <Footer/>
    </Wrapper>
  );
};
export default DefaultLayout;
