import {ReactNode, useEffect, useState} from 'react';

import {LanguageContext} from './languageContext';

import {getItem, setItem} from '../../localStorage';
import {LOCAL_STORAGE_NAMES} from '../../localStorage/types';

type ILanguageProviderProps = {
    children: ReactNode
}

export const LanguageProvider = ({children}: ILanguageProviderProps) => {
  const [language, setLanguage] = useState<string>('');
  const [texts, setTexts] = useState<any>({});

  const switchLanguage = (lang: string) => {
    setLanguage(lang);
    setItem(LOCAL_STORAGE_NAMES.LANGUAGE, lang);
  };

  const getText = (path: string) => {
    const keys = path.split('.');
    let value = texts;
    for (const key of keys) {
      value = value[key];
      if (!value) return '';
    }
    return value || '';
  };

  const loadTexts = async (lang: string) => {
    try {
      const response = await import(`../../texts/${lang}.json`);
      setTexts(response);
    } catch {
      console.error('Error loading texts');
    }
  };

  useEffect(() => {
    loadTexts(language).catch(console.error);
  }, [language]);

  useEffect(() => {
    const storageLanguage = getItem(LOCAL_STORAGE_NAMES.LANGUAGE);
    if (!storageLanguage) {
      setLanguage('en');
    } else {
      setLanguage(String(storageLanguage))
    }
  }, []);

  return (
    <LanguageContext.Provider
      value={{language, switchLanguage, getText, texts}}>
      {children}
    </LanguageContext.Provider>
  )
}